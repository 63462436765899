import "./blogs.css";
import React from "react";

import bitcoin1 from "../../assets/bitcoin1.png";
import bitcoin2 from "../../assets/bitcoin2.png";
import bitcoin3 from "../../assets/bitcoin3.png";

const Blogs = () => {
  const blogs = [
    {
      name: "Insights, Loan Originators  ·  15.02.2024",
      image: bitcoin1,
      content1: "Third-Quarter 2023 Financial Results & CEO’s Foreword",
      content2:
        "Today is the day our investors wait for the most - we reveal the financial results of Q3 of 2023…",
    },
    {
      name: "Insights, Loan Originators  ·  15.02.2024",
      image: bitcoin2,
      content1: "Third-Quarter 2023 Financial Results & CEO’s Foreword",
      content2:
        "Today is the day our investors wait for the most - we reveal the financial results of Q3 of 2023…",
    },
    {
      name: "Insights, Loan Originators  ·  15.02.2024",
      image: bitcoin3,
      content1: "Third-Quarter 2023 Financial Results & CEO’s Foreword",
      content2:
        "Today is the day our investors wait for the most - we reveal the financial results of Q3 of 2023…",
    },
  ];

  return (
    <div style={{ paddingTop: "4rem" }}>
      <div className="articles-container">
        <span className="customer">Latest Articles</span>
        <div style={{ margin: "2rem 0" }}>
          <div className="card_wrapper">
            {blogs.map((cust, index) => (
              <a
                href="https://koryntia.medium.com/"
                target="_blank"
                className="card-container"
                rel="noreferrer"
                key={index}
              >
                <img
                  src={cust.image}
                  className="card-images"
                />

                <div className="customer_name">
                  <span>{cust.name}</span>
                </div>
                <div className="customer_detail">
                  <p>{cust.content1}</p>
                </div>
                <p className="description">{cust.content2}</p>
              </a>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Blogs;
