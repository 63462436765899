import "./footer.css";
import React from "react";
import logo from "../../assets/logo.png";
import x from "../../assets/x.png";
import { FaDiscord, FaGithub, FaTelegram } from "react-icons/fa6";

const Footer = () => {
  return (
    <>
      <footer>
        <a
          href="https://koryntia.finance"
          className="footer-box first-box"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <img
            src={logo}
            alt="Logo"
            height="50px"
            style={{ marginTop: "40px" }}
          />
        </a>

        <div className="footer-box second-box">
          <a
            href="https://koryntia.finance/"
            className="footer-links"
          >
            Home
          </a>
          <a
            href="https://koryntia.gitbook.io/"
            className="footer-links"
          >
            Docs
          </a>
          <a
            href="https://linktr.ee/koryntia"
            className="footer-links"
            target="_blank"
            rel="noreferrer"
          >
            Community
          </a>
          <a
            href="https://github.com/Koryntia/Koryntia-Whitepaper-Tokenomics-V2/blob/main/Koryntia%20Whitepaper%20Tokenomics%20V2.pdf"
            target="_blank"
            rel="noreferrer"
            className="footer-links"
          >
            Whitepaper
          </a>
        </div>
        <div className="footer-box second-box">
          <a
            href="https://drive.google.com/drive/folders/1YNrgt_041LIVP3iRWeVut1qseokV1REc?usp=sharing"
            className="footer-links"
            target="_blank"
            rel="noreferrer"
          >
            Branding
          </a>
          <a
            href="https://koryntia.medium.com/"
            className="footer-links"
            target="_blank"
            rel="noreferrer"
          >
            Blog
          </a>
          <a
            href="https://koryntia.substack.com/"
            target="_blank"
            rel="noreferrer"
            className="footer-links"
          >
            Academy
          </a>
        </div>
        <div className="footer-box second-box">
          <a
            href="https://koryntia.gitbook.io/koryntia-finance/legal/cookie-policy"
            className="footer-links"
            target="_blank"
            rel="noreferrer"
          >
            Cookie policy
          </a>
          <a
            href="https://koryntia.gitbook.io/koryntia-finance/legal/privacy-policy"
            className="footer-links"
            target="_blank"
            rel="noreferrer"
          >
            Privacy Policy
          </a>
          <a
            href="https://koryntia.gitbook.io/koryntia-finance/legal/user-agreement"
            className="footer-links"
            target="_blank"
            rel="noreferrer"
          >
            User agreement
          </a>
          <a
            href="mailto:info@koryntia.finance"
            className="footer-links"
          >
            Contact
          </a>
        </div>

        <div
          className="footer-box last-box"
          style={{ paddingTop: "55px" }}
        >
          <div className="footer-box-images">
            <a
              href="https://x.com/KoryntiaFinance"
              target="_blank"
              rel="noreferrer"
            >
              <div
                style={{ height: "50px", width: "36px", display: "flex", flexDirection: "column" }}
              >
                <img
                  src={x}
                  alt="x"
                  style={{
                    display: "block",
                    width: "100%",
                    alignSelf: "flex-start",
                    marginTop: "-1.3px",
                    marginLeft: "5.3px",
                  }}
                />
              </div>
            </a>
            <a
              href="https://t.me/koryntia"
              target="_blank"
              rel="noreferrer"
            >
              <FaTelegram
                size={23}
                fill="#fff"
              />
            </a>
            <a
              href="https://discord.com/invite/EwwNEjqDnv"
              target="_blank"
              rel="noreferrer"
            >
              <FaDiscord
                size={23}
                fill="#fff"
              />
            </a>
            <a
              href="https://github.com/Koryntia"
              target="_blank"
              rel="noreferrer"
            >
              <FaGithub
                size={23}
                fill="#fff"
              />
            </a>
          </div>
        </div>
      </footer>
      <div className="copyright-area">
        <div className="left-copyright-area">&copy; 2024 Koryntia</div>
        <div className="right-copyright-area">
          With all investments your capital is at risk and the value of your investments and the
          income deriving from it can rise as well as fall. Past performance is not a guide to
          future performance.
        </div>
      </div>
    </>
  );
};

export default Footer;
