import React from "react";
import Header from "../components/header/Header";
import Hero from "../components/hero/Hero";
import Welcome from "../components/welcome/Welcome";
import Investing from "../components/investing/Investing";
import Blogs from "../components/blogs/Blogs";
import Footer from "../components/footer/Footer";

const home = () => {
  return (
    <>
      <div>
        <Header />
        <Hero />
        <Welcome />
        <Investing />
        <Blogs />
        <Footer />
      </div>
    </>
  );
};

export default home;
