import "./header.css";
import React from "react";
import { useState } from "react";
import logo from "../../assets/logo.png";
import { FaBarsStaggered } from "react-icons/fa6";
import Sidebar from "../sidebar/Sidebar";

const Header = () => {
  const [responsive, setResponsive] = useState(false);
  return (
    <div className="header">
      <Sidebar
        responsive={responsive}
        setResponsive={setResponsive}
      />
      <div className="header-container">
        <div className="logo-container">
          <img
            src={logo}
            alt="Logo"
            className="logo"
          />
        </div>
        <div className="navigation-container">
          <ul className="navigation-list">
            <li className="navigation-item">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://koryntia.gitbook.io/"
              >
                Docs
              </a>
            </li>
            <li className="navigation-item">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://linktr.ee/koryntia"
              >
                Community
              </a>
            </li>
            <li className="navigation-item">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://github.com/Koryntia/Koryntia-Whitepaper-Tokenomics-V2/blob/main/Koryntia%20Whitepaper%20Tokenomics%20V2.pdf"
              >
                Whitepapper
              </a>
            </li>
          </ul>
          <div
            style={{
              width: "1px",
              height: "20px",
              backgroundColor: "white",
              margin: "0 15px",
            }}
          ></div>
          <a
            href="https://app.koryntia.finance/"
            className="launch-app-button"
            target="_blank"
            rel="noreferrer"
          >
            Launch App
          </a>
        </div>
        <div className="menu_close_icon">
          <span
            onClick={() => {
              setResponsive(true);
              console.log("clicked");
              document.querySelector("body").style.overflowY = "hidden";
            }}
          >
            <FaBarsStaggered
              size={24}
              fill="#ffffff"
            />
          </span>
        </div>
      </div>
    </div>
  );
};

export default Header;
