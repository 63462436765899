import React from "react";
import "./welcome.css";
import efficiencyicon from "../../assets/efficiency.png";
import arrow from "../../assets/arrow.svg";
import alma from "../../assets/alma.png";
import globe from "../../assets/globe.png";
import accessible from "../../assets/accessible.png";
import pointer from "../../assets/pointer.png";

const Welcome = () => {
  return (
    <div
      class="parent"
      style={{ display: "flex", width: "100%" }}
    >
      <div className="child1">
        <div className="child1-left">
          <img src={efficiencyicon} />
          <p className="efficiency-text">Efficient Capital Management</p>
        </div>
        <div className="child1-left">
          <img src={alma} />
          <p className="text-light">Streamlined Experience</p>
        </div>
        <div className="child1-left">
          <img src={globe} />
          <p className="text-light">Empower Your Financial Future</p>
        </div>
        <div className="child1-left">
          <img src={alma} />
          <p className="text-light">Secure and Transparent</p>
        </div>
        <div className="child1-left">
          <img src={accessible} />
          <p className="text-light">Accessible to All</p>
        </div>
      </div>
      <div className="child2">
        <img
          src={arrow}
          className="efficiency-arrow"
        />
      </div>

      <div className="child3">
        <p className="capital-efficiency-text">Efficient Capital Management</p>
        <p className="sub-text">
          Harness the power of capital efficiency with our innovative protocol. Whether you're
          lending or borrowing, our platform is designed to optimize your capital allocation,
          maximizing your earning potential.
        </p>
        <br />
        <div className="points-wrapper">
          <div className="child3-points">
            <img src={pointer} />
            <p>
              Seamlessly allocate your capital across various investment opportunities to maximize
              returns and minimize risk.
            </p>
          </div>
          <div className="child3-points">
            <img src={pointer} />
            <p>
              Utilize advanced risk management strategies to protect your assets and maintain a
              balanced investment portfolio.
            </p>
          </div>
          <div className="child3-points">
            <img src={pointer} />
            <p>
              Utilize advanced risk management strategies to protect your assets and maintain a
              balanced investment portfolio.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Welcome;
