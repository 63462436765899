import "./sidebar.css";
import React from "react";
import { IoIosCloseCircle } from "react-icons/io";
import { Link } from "react-router-dom";

const Sidebar = ({ responsive, setResponsive }) => {
  console.log(responsive);
  return (
    <div className={`sidebar ${responsive ? "sidebar_show" : "sidebar_hide"}`}>
      <div className="sidebar_header">
        <span
          onClick={() => {
            setResponsive(false);
            document.querySelector("body").style.overflowY = "scroll";
          }}
        >
          <IoIosCloseCircle
            size={26}
            style={{ transform: "rotateY(180deg)" }}
          />
        </span>
      </div>
      <div className="sidebar_list">
        <ul className="sidebar_items">
          <li>
            <a
              target="_blank"
              rel="noreferrer"
              href="/https://koryntia.gitbook.io/"
            >
              Docs
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://linktr.ee/koryntia"
            >
              Community
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://github.com/Koryntia/Koryntia-Whitepaper-Tokenomics-V2/blob/main/Koryntia%20Whitepaper%20Tokenomics%20V2.pdf"
            >
              Whitepaper
            </a>
          </li>
        </ul>

        <hr style={{ margin: "10px 20px" }} />
        <div style={{ padding: "1rem" }}>
          <a
            href="https://app.koryntia.finance/"
            className="sidebar_btn"
            target="_blank"
            rel="noreferrer"
          >
            Launch App
          </a>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
