import "./investing.css";
import React from "react";
import invest from "../../assets/invest.png";
import { IoChevronForward } from "react-icons/io5";
import invested from "../../assets/invested.svg";
import arrow from "../../assets/rotatedarrow.svg";
import average from "../../assets/annual.svg";
import investors from "../../assets/investors.svg";

const Dishes = () => {
  return (
    <div className="invest-container">
      <div className="invest-left">
        <div className="invest-cards">
          <div className="invest-cards-data">
            <img src={invested} />
            <div className="data-wrapper">
              <p className="data-text">Total invested</p>
              <p className="cards-no">€ 34.33 M</p>
            </div>
          </div>
          <div className="invest-cards-data">
            <img src={arrow} />
            <div className="data-wrapper">
              <p className="data-text">Repaid amount</p>
              <p className="cards-no">€ 29.05 M</p>
            </div>
          </div>
          <div className="invest-cards-data">
            <img src={average} />
            <div className="data-wrapper">
              <p className="data-text">Average Annual Investment Return</p>
              <p className="cards-no">15.35%</p>
            </div>
          </div>
          <div className="invest-cards-data">
            <img src={investors} />
            <div className="data-wrapper">
              <p className="data-text">Number of Investors</p>
              <p className="cards-no">9965</p>
            </div>
          </div>
        </div>
      </div>
      <div className="invest-right">
        <div className="invest-right-container">
          <img
            src={invest}
            alt="invest-img"
            className="invest-img"
          />
          <div className="overlay">
            <p>Investing doesn't have to be complicated – high five to simplicity</p>
          </div>

          <a
            href="https://app.koryntia.finance/"
            target="_blank"
            rel="noreferrer"
            className="invest-button"
          >
            Start investing
            <IoChevronForward />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Dishes;
