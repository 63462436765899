import "./hero.css";
import React from "react";
import bg from "../../assets/bg.png";

const Hero = () => {
  return (
    <div className="hero">
      <div className="hero-container">
        <div className="left-section">
          <h1 class="section_qoute">
            Maximize Returns{" "}
            <span style={{ color: "rgba(215, 255, 76, 1)" }}>Optimize Efficiency </span> Join Today.
          </h1>
          <p class="section_content">
            Discover unparalleled opportunities to maximize your returns and optimize capital
            efficiency like never before.
          </p>
          <div className="banner-btn-wrapper">
            <a
              href="https://app.koryntia.finance/"
              target="_blank"
              rel="noreferrer"
              className="invest-btn"
            >
              Start investing
            </a>
          </div>
        </div>
        <div className="right-section">
          <div>
            <img
              src={bg}
              className="banner-img"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
